@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  font-family: "latoRegular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "latoBold";
  src: url("./assets/fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "latoBold_Italic";
  src: url("./assets/fonts/Lato-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "latoItalic";
  src: url("./assets/fonts/Lato-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "latoRegular";
  src: url("./assets/fonts/Lato-Regular.ttf") format("truetype");
}
